import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { ContentstackText } from 'components/contentstack/contentstack-text';
import { selectBuyItAgainProducts, selectLastRequestedSku } from 'store/home/selectors';
import { addProductToCart } from 'store/shopping-cart/actions';
import { selectIsImpersonateModeOn, selectIsTsrUser } from 'store/auth/selectors';
import { QuantityControl } from 'components/quantity-control';
import { ProductStatusLabels } from 'components/product-status-labels';
import { stripLeadingZeros } from 'utils/string-format';
import { IAddProductToCartPayload } from 'store/shopping-cart/sagas/add-product-to-cart';
import { formatCurrency } from 'utils/currency-format';
import {
  useDiscontinuedItemsWithReplacementsModal,
  useHideGlobalModalHandler,
  useRequestQuoteModal,
  useRequestQuoteSuccessMessageModal,
} from 'hooks/use-global-modal';
import { BuyItAgainItemButtons } from 'features/order-updates/components/buy-it-again/buy-it-again-item/components/buy-it-again-item-item-buttons';
import { IProductItem } from 'types/product';
import { getBuyItAgainSkuSubtotal, setLastRequestSku } from 'store/home/actions';

import './buy-it-again-skus-slider-item.scss';

export interface IBuyItAgainSkusSliderItemProps extends IProductItem {
  onRequestQuoteCtaClick?: (skuKey: string, isOpenedWithinQuickShop: boolean) => void;
  index: number;
}

export const BuyItAgainSkusSliderItem: React.FC<IBuyItAgainSkusSliderItemProps> = ({
  onRequestQuoteCtaClick,
  number,
  price,
  isSpecial,
  isPhasingOut,
  isDiscontinued,
  replacementItems,
  maximumQuantity,
  productKey,
  isOutOfStock,
  outOfStockSimilarItems,
  name,
  brand,
  index,
  totalPrice,
}) => {
  const dispatch = useDispatch();
  const buyItAgainProducts = useSelector(selectBuyItAgainProducts);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);
  const isTsrUser = useSelector(selectIsTsrUser);
  const [buyItAgainTotalPrice, setBuyItAgainTotalPrice] = useState({
    amount: price?.amount,
    currencySymbol: price?.currencySymbol,
  });

  const lastRequestSku = useSelector(selectLastRequestedSku);
  const quantityInputRef = useRef<HTMLInputElement>(null);

  const isDiscontinuedWithReplacement = (isDiscontinued, replacementItems) =>
    isDiscontinued && !isEmpty(replacementItems);

  useEffect(() => {
    lastRequestSku === number &&
      totalPrice &&
      setBuyItAgainTotalPrice({ amount: totalPrice.amount, currencySymbol: totalPrice.currencySymbol });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice]);

  const addToCart = () => {
    if (quantityInputRef.current) {
      dispatch(
        addProductToCart.request<IAddProductToCartPayload>({
          quantity: Number(quantityInputRef.current.value),
          sku: number,
        })
      );
    }
  };

  const setQuantity = (quantity?: number) => {
    if (quantity && !isSpecial) {
      dispatch(setLastRequestSku(number));

      dispatch(getBuyItAgainSkuSubtotal.request({ sku: number, amount: quantity }));
    }
  };

  const onAddToCartClick = () => {
    setIsButtonActive(true);
    addToCart();
    setButtonDisabled(true);
    setTimeout(() => {
      setIsButtonActive(false);
      setButtonDisabled(false);
    }, 1000);
  };

  const showRequestQuoteSuccessModal = useRequestQuoteSuccessMessageModal();
  const hideGlobalModal = useHideGlobalModalHandler();

  const showRequestQuoteModal = useRequestQuoteModal();

  const showDiscontinuedItemsWithReplacementsModal = useDiscontinuedItemsWithReplacementsModal();

  return (
    <div className="buy-it-again-skus-slider-item">
      <div className="buy-it-again-skus-slider-item__content">
        <div className="buy-it-again-skus-slider-item__counter grid-container">
          <ContentstackText
            contentKey="page_content.buy_it_again[0].products_counter"
            interpolateParams={{ currentProductIndex: ++index, productsCount: buyItAgainProducts?.length }}
          />
        </div>
        <div className="buy-it-again-skus-slider-item__info">
          <div className="grid-x grid-container">
            <span className="buy-it-again-skus-slider-item__item-info buy-it-again-skus-slider-item__item-name">
              {name}
            </span>
            <span className="buy-it-again-skus-slider-item__item-info buy-it-again-skus-slider-item__item-number">
              <ContentstackText contentKey="page_content.buy_it_again[0].item_number_label" />{' '}
              {stripLeadingZeros(number)}
              {(isDiscontinued || isSpecial || isPhasingOut || isOutOfStock) && (
                <ProductStatusLabels
                  isDiscontinued={isDiscontinued}
                  isSpecialOrder={isSpecial}
                  isPhasingOut={isPhasingOut && !isOutOfStock && !isSpecial}
                  isOutOfStock={isOutOfStock || (isOutOfStock && isPhasingOut)}
                />
              )}
            </span>
            <span className="buy-it-again-skus-slider-item__item-info buy-it-again-skus-slider-item__item-brand">
              {brand}
            </span>
            {!isOutOfStock && !isSpecial && !isDiscontinued && (
              <div className="buy-it-again-skus-slider-item__price-block">
                {price && (
                  <div className="buy-it-again-skus-slider-item__price">
                    <p className="buy-it-again-skus-slider-item__price-label">
                      <ContentstackText contentKey="page_content.buy_it_again[0].price_label" />
                    </p>
                    <p className="buy-it-again-skus-slider-item__price-value">{formatCurrency(price)}</p>
                  </div>
                )}
                <div className="buy-it-again-skus-slider-item__price-quantity">
                  <p className="buy-it-again-skus-slider-item__price-quantity-label">
                    <ContentstackText contentKey="page_content.buy_it_again[0].quantity_label" />
                  </p>
                  <QuantityControl
                    quantity={1}
                    contentStackPath={'page_content.buy_it_again[0]'}
                    inputRef={quantityInputRef}
                    onDecreaseQuantityHandler={setQuantity}
                    onIncreaseQuantityHandler={setQuantity}
                    setCurrentQuantity={setQuantity}
                  />
                </div>
                {price && (
                  <div className="buy-it-again-skus-slider-item__total-price">
                    <p className="buy-it-again-skus-slider-item__total-price-label buy-it-again-skus-slider-item__price-label">
                      <ContentstackText contentKey="page_content.buy_it_again[0].total_label" />
                    </p>
                    <div className="buy-it-again-skus-slider-item__total-price-value buy-it-again-skus-slider-item__price-value">
                      {formatCurrency(buyItAgainTotalPrice)}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {(!isImpersonateModeOn || (isImpersonateModeOn && !isTsrUser)) && (
          <div className="buy-it-again-skus-slider-item__buttons">
            <div className="grid-container">
              <BuyItAgainItemButtons
                contentstackPath={'page_content.buy_it_again[0]'}
                sku={number}
                productKey={productKey}
                isSpecialOrder={Boolean(isSpecial)}
                isDiscontinuedWithReplacement={Boolean(isDiscontinuedWithReplacement(isDiscontinued, replacementItems))}
                active={isButtonActive}
                disabled={isButtonDisabled || maximumQuantity === 0}
                onAddToCartClick={onAddToCartClick}
                isOutOfStock={Boolean(isOutOfStock)}
                outOfStockSimilarItems={outOfStockSimilarItems}
                openDiscontinuedWithReplacementModal={() =>
                  showDiscontinuedItemsWithReplacementsModal({
                    messageType: 'error',
                    messageId: 'MSG021a',
                    openPreviousModal: () => showDiscontinuedItemsWithReplacementsModal(),
                    originalToReplacementItemsMap: { [number]: replacementItems[0] },
                  })
                }
                onRequestQuoteCtaClick={() => {
                  if (onRequestQuoteCtaClick) {
                    onRequestQuoteCtaClick(number, true);
                    return;
                  }
                  showRequestQuoteModal({
                    skuKey: number,
                    requestQuoteSuccessCallBack: showRequestQuoteSuccessModal,
                    requestQuoteFailCallBack: hideGlobalModal,
                  });
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
