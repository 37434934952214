import './cor-small-card-product.scss';

import React, { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import {
  ICorFeaturedContentCardVariant,
  ICorFeaturedContentEntry,
} from 'corporate-components/cor-featured-content/cor-featured-content.interface';
import { CorContentstackImage, CorContentstackLink } from 'corporate-components/cor-contentstack';
import { CorTypography } from 'corporate-ui';
import { CorProductStatusLabels } from 'corporate-components/cor-product-status-labels';
import { CorProductNewArrivalBadge } from 'corporate-components/cor-product-new-arrival-badge';
import { useContent } from 'hooks/use-content';
import {
  useHideGlobalModalHandler,
  useQuickShopModal,
  useRequestQuoteModal,
  useRequestQuoteSuccessMessageModal,
} from 'hooks/use-global-modal';
import { CorButton } from 'components/cor-button';
import { selectIsTsrUser } from 'store/auth/selectors';
import { selectIsKeyAccountUser } from 'store/auth/selectors';
import { IMAGES_COMPRESSION_LEVEL, IMAGES_FORMAT } from 'constants/contentstack';
import { CustomTooltip } from 'components/custom-tooltip';
import iconInfo from 'assets/images/icons/icon-info.svg';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { Routes } from 'constants/routes.enum';

const CorSmallCardProduct = ({
  contentKeyBase,
  labelContentKey,
  notifications,
  tooltips,
  specialOrderIcon,
}: ICorFeaturedContentCardVariant) => {
  const { isMobile } = useBreakpoint();
  const { getContentByKey } = useContent();
  const isTsr: boolean = useSelector(selectIsTsrUser);
  const isKeyAccountUser: boolean = useSelector(selectIsKeyAccountUser);
  const entry: ICorFeaturedContentEntry = getContentByKey(contentKeyBase, {});
  const { sku, image, title, description, productKey } = entry;
  const pageUrl = getContentByKey(`${contentKeyBase}.pageUrl`, { href: '', title: '' });
  const corLinkData = productKey
    ? {
        href: `${Routes.ProductDetailsPage}/${productKey}?selectedSku=${sku?.sku ?? ''}`,
        title: pageUrl.title,
      }
    : undefined;

  const itemProductKey = productKey ?? pageUrl.href?.slice(14);
  const specialOrderMessage = notifications?.filter((notification) => notification.message_id === 'MSG162');
  const specialOrderTooltip = tooltips?.find((tooltip) => tooltip.message_id === 'MSG107');
  const hasImageOverlay = sku?.isDiscontinued || sku?.isOutOfStock;

  const hideGlobalModal = useHideGlobalModalHandler();
  const showRequestquoteSuccessModal = useRequestQuoteSuccessMessageModal();
  const showQuickShopModal = useQuickShopModal();
  const showRequestQuoteModal = useRequestQuoteModal({
    requestQuoteSuccessCallBack: () => showRequestquoteSuccessModal(),
    requestQuoteFailCallBack: hideGlobalModal,
  });

  const onRequestQuoteCtaClick = (skuKey: string) => {
    showRequestQuoteModal({ skuKey, requestQuoteSuccessCallBack, requestQuoteFailCallBack });
  };

  const onAddToCartButtonClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    showQuickShopModal({
      productKey: itemProductKey,
      onRequestQuoteCtaClick,
      requestQuoteSuccessCallBack,
      requestQuoteFailCallBack,
    });
  };

  const requestQuoteSuccessCallBack = () => {
    showRequestquoteSuccessModal();
  };

  const requestQuoteFailCallBack = () => {
    hideGlobalModal();
  };

  const getProductButtonLabel = () => {
    if (!labelContentKey) return;

    if (sku?.isDiscontinued && !isEmpty(sku?.replacementSkus)) {
      return labelContentKey.see_alternative;
    }

    if (sku?.isSpecialOrder) {
      return labelContentKey.request_quote;
    }

    return labelContentKey.buy_now;
  };

  const renderProductCardButton = () => {
    const productStatusLabel = getProductButtonLabel();
    const keyAccountSpecialOrderMessage = String(specialOrderMessage?.[0]?.message_content ?? '');

    return sku?.isSpecialOrder && isKeyAccountUser ? (
      <div className="cor-featured-content__card-product-key-account">
        <img
          src={`${specialOrderIcon?.url}?quality=${IMAGES_COMPRESSION_LEVEL}&format=${IMAGES_FORMAT}`}
          alt={specialOrderIcon?.title}
          className="cor-featured-content__card-product-key-account-img"
        />
        <span className="cor-featured-content__card-product-key-account-message">{keyAccountSpecialOrderMessage}</span>
      </div>
    ) : (
      <div className="cor-featured-content__card-product-button-container">
        <CorButton
          className="cor-featured-content__card-product-button"
          color="tertiary"
          onClick={(event) => onAddToCartButtonClick(event)}
        >
          {productStatusLabel}
        </CorButton>
        {sku?.isSpecialOrder && (
          <CustomTooltip
            overrideTooltipPosition={!isMobile}
            customIcon={iconInfo}
            showTooltip={!!specialOrderTooltip}
            tooltipId={`cardProductSpecialOrder-${itemProductKey}`}
          >
            {specialOrderTooltip?.message_content}
          </CustomTooltip>
        )}
      </div>
    );
  };

  return (
    <div className="cor-featured-content__card-product cor-featured-content__card cor-featured-content__card--small-size">
      <CorContentstackLink
        className="cor-featured-content__card-product__image__container cor-featured-content__card__image__container"
        contentKey={`${contentKeyBase}.pageUrl`}
        data={corLinkData}
        fallbackElement="div"
      >
        <div className="cor-featured-content__card-product__media-container">
          <div
            className={classNames('cor-featured-content__card-product__media', {
              'cor-featured-content__card-product__media--with-overlay': hasImageOverlay,
            })}
          >
            {!!image && (
              <CorContentstackImage
                contentKey={`${contentKeyBase}.image`}
                className={classNames('cor-featured-content__card-product__image cor-featured-content__card__image', {
                  'cor-featured-content__card-product__image--with-overlay': hasImageOverlay,
                })}
              />
            )}
          </div>
          {(sku?.isDiscontinued || sku?.isSpecialOrder || sku?.isPhasingOut || sku?.isOutOfStock) && (
            <div className="cor-featured-content__card-product__statuses-wrapper">
              <CorProductStatusLabels
                size={isMobile ? 'small' : 'large'}
                labelContentKey={labelContentKey}
                isSpecialOrder={sku?.isSpecialOrder}
                isDiscontinued={sku?.isDiscontinued}
                isOutOfStock={sku?.isOutOfStock}
                isPhasingOut={sku?.isPhasingOut}
              />
            </div>
          )}
        </div>
      </CorContentstackLink>
      <div className="cor-featured-content__card-product__description">
        {!!title && (
          <CorTypography
            variant="h4"
            className="cor-featured-content__card-product__description__category"
            data-testid="cor-featured-content__card-product__title"
          >
            {sku?.newArrival && <CorProductNewArrivalBadge labelcontentKey={labelContentKey} />}
            <span>{title}</span>
          </CorTypography>
        )}

        {!!description && (
          <CorTypography
            variant="body-regular"
            className="cor-featured-content__card-product__description__content"
            data-testid="cor-featured-content__card-product__description"
          >
            {description}
          </CorTypography>
        )}
      </div>
      {!isTsr && renderProductCardButton()}
    </div>
  );
};

export default React.memo(CorSmallCardProduct);
