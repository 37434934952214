import { useEffect, useState } from 'react';
import { isEmpty, size } from 'lodash-es';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getHomeShoppingLists } from 'store/home/actions';
import { selectHomeShoppingLists, selectHomeShoppingListsTotal } from 'store/home/selectors';
import { EmptyShoppingList } from 'features/order-updates/components/empty-shopping-list';
import { Routes } from 'constants/routes.enum';
import iconChevron from 'assets/images/icons/icon-chevron-black.svg';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { CorButton } from 'components/cor-button';
import { ContentstackMessage, ContentstackText } from 'components/contentstack';
import { ShoppingListSlider } from 'features/order-updates/components/shopping-list-slider';
import { CustomTooltip } from 'components/custom-tooltip';
import { Permissions } from 'constants/permissions';
import { AccessDefinitions } from 'constants/access-definitions.enum';
import { PermissionAccess } from 'components/permission-access';
import { addShoppingListToCart } from 'store/shopping-lists/actions';
import { IAddShoppingListToCartPayload } from 'store/shopping-lists/sagas/add-list-to-cart';
import { getCartInfo } from 'store/shopping-cart/actions';
import { selectIsUserLocationDeleted, selectUserLocationId } from 'store/auth/selectors';
import { useReorder } from 'hooks/use-reorder';
import { IShoppingListProduct } from 'types/product';
import { useCreateList } from 'hooks/use-create-list';
import { useHowToCreateShoppingListModal } from 'hooks/use-global-modal';
import infoIcon from 'assets/images/icons/icon-info.svg';
import infoIconDisabled from 'assets/images/icons/icon-info-disabled.svg';
import { useAddToShoppingList } from 'hooks/use-add-to-shopping-list';

import './shopping-list.scss';

export const ShoppingList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useBreakpoint();
  const shoppingLists = useSelector(selectHomeShoppingLists);
  const shoppingListsTotal = useSelector(selectHomeShoppingListsTotal);
  const userLocationId = useSelector(selectUserLocationId);
  const contentStackPath = 'page_content.shopping_list[0].shopping_list_tab_content';

  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);
  const isUnavailableForPurchaseItemsOnly = (items) =>
    items?.every(
      (item) =>
        item.isSpecialOrder || item.isDiscontinued || item.isOutOfStock || item.maximumQuantity === 0 || item.isInactive
    );

  const [isReorderButtonDisabled, setReorderButtonDisabled] = useState(false);

  const showHowToCreateShoppingListModal = useHowToCreateShoppingListModal({
    contentStackPath: 'modals.0.how_to_create_shopping_list_modal.0',
  });

  const customSLTotalWithoutFavorites = shoppingListsTotal - 1;

  useReorder();

  useEffect(() => {
    if (!shoppingLists) {
      dispatch(getHomeShoppingLists.request());
    }
  }, [dispatch, shoppingLists]);

  const { createEmptyListHandler: createListHandler } = useCreateList({});

  const openCreateListModalHandler = () => {
    showCreateShoppingListModal();
  };

  const { showCreateShoppingListModal } = useAddToShoppingList({
    createListHandler,
    forceInitialRequest: false,
    openCreateListModalHandler,
  });

  const addListToCart = ({
    totalItems,
    id,
    totalQuantity,
    items,
  }: {
    totalItems: number;
    id: string;
    totalQuantity: number;
    items: IShoppingListProduct[];
  }) => {
    if (totalItems) {
      setReorderButtonDisabled(true);
      setTimeout(() => setReorderButtonDisabled(false), 1000);

      dispatch(
        addShoppingListToCart.request<IAddShoppingListToCartPayload>({
          shoppingListId: id,
          locationId: userLocationId,
          totalQuantity,
          items,
          onSuccessCallback: () => {
            dispatch(getCartInfo.request());
          },
        })
      );
    }
  };

  const renderShoppingListTooltip = () => (
    <button
      className="shopping-list__create-list-tooltip"
      disabled={isUserLocationDeleted}
      onClick={() => showHowToCreateShoppingListModal()}
    >
      <CustomTooltip
        tooltipId="how-to-create-list"
        customIcon={!isUserLocationDeleted ? infoIcon : infoIconDisabled}
        showTooltip={!isUserLocationDeleted}
        hasClickHandling
      >
        <ContentstackMessage type="tooltips" messageId="MSG150" />
      </CustomTooltip>
    </button>
  );

  const renderSlider = () => (
    <>
      <div className="shopping-list__slider">
        <ShoppingListSlider addListToCartHandler={addListToCart} isReorderButtonDisabled={isReorderButtonDisabled} />
      </div>
      <div className="shopping-list__action">
        {customSLTotalWithoutFavorites >= 6 ? (
          <Link className="shopping-list__see-all" to={`${Routes.AccountShoppingListsPage}`}>
            <ContentstackText contentKey={`${contentStackPath}.see_all_link_text`} />
            <img src={iconChevron} alt="icon expand" />
          </Link>
        ) : (
          <div className="shopping-list__add-more">
            <CorButton
              color="text"
              className="shopping-list__add-more-button"
              onClick={() => {
                openCreateListModalHandler();
              }}
            >
              <ContentstackText contentKey={`${contentStackPath}.add_more_button_text`} />
            </CorButton>
            {renderShoppingListTooltip()}
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className="shopping-list">
      {isEmpty(shoppingLists) ? (
        <EmptyShoppingList />
      ) : isMobile ? (
        renderSlider()
      ) : (
        <div className="shopping-list__desktop-view">
          <div className="grid-x align-center">
            <div className="shopping-list__column-name shopping-list__list-name">
              <ContentstackText contentKey={`${contentStackPath}.list_name_label`} />
            </div>
            <div className="shopping-list__column-name shopping-list__total-items">
              <ContentstackText contentKey={`${contentStackPath}.total_number_of_items_label`} />
            </div>
            <div className="shopping-list__column-name shopping-list__total-quantity">
              <ContentstackText contentKey={`${contentStackPath}.total_quantity_label`} />
            </div>
            <div className="shopping-list__action">
              {customSLTotalWithoutFavorites > 6 ? (
                <Link className="shopping-list__see-all" to={`${Routes.AccountShoppingListsPage}`}>
                  <ContentstackText contentKey={`${contentStackPath}.see_all_link_text`} />
                  <img src={iconChevron} alt="icon expand" />
                </Link>
              ) : (
                <div className="shopping-list__add-more">
                  <CorButton
                    color="text"
                    className="shopping-list__add-more-button"
                    onClick={() => {
                      openCreateListModalHandler();
                    }}
                  >
                    <ContentstackText contentKey={`${contentStackPath}.add_more_button_text`} />
                  </CorButton>
                  {renderShoppingListTooltip()}
                </div>
              )}
            </div>
          </div>
          {shoppingLists?.map(({ id, name, totalItems, totalQuantity, items }) => (
            <div className="shopping-list__list grid-x" key={id}>
              <div className="shopping-list__info-block shopping-list__list-name">{name}</div>
              <div className="shopping-list__info-block shopping-list__total-items">{totalItems}</div>
              <div className="shopping-list__total-quantity">{totalQuantity}</div>
              <div className="shopping-list__actions">
                {size(items) >= 1 && (
                  <PermissionAccess requiredPermissions={Permissions[AccessDefinitions.SHOPPING_CART_ACTIONS]}>
                    <CorButton
                      className="shopping-list__add-all"
                      color="text"
                      onClick={() => addListToCart({ id, items, totalItems, totalQuantity })}
                      disabled={
                        isReorderButtonDisabled || isUserLocationDeleted || isUnavailableForPurchaseItemsOnly(items)
                      }
                    >
                      <ContentstackText contentKey={`${contentStackPath}.add_all_to_cart_button_text`} />
                    </CorButton>
                  </PermissionAccess>
                )}
                <CorButton
                  className="shopping-list__view-list"
                  color="text"
                  onClick={() => history.push(`${Routes.ShoppingListDetailsPage}/${id}`)}
                >
                  <ContentstackText contentKey={`${contentStackPath}.view_list_link_text`} />
                </CorButton>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
