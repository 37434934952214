import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { size } from 'lodash-es';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { selectHomeShoppingLists } from 'store/home/selectors';
import { ContentstackText } from 'components/contentstack';
import { PermissionAccess } from 'components/permission-access';
import { Permissions } from 'constants/permissions';
import { AccessDefinitions } from 'constants/access-definitions.enum';
import { CorButton } from 'components/cor-button';
import { Routes } from 'constants/routes.enum';
import { selectIsUserLocationDeleted } from 'store/auth/selectors';
import { useReorder } from 'hooks/use-reorder';
import { IShoppingListProduct } from 'types/product';

import './shopping-list-slider.scss';

interface IShoppingListSliderProps {
  addListToCartHandler: (props: {
    totalItems: number;
    id: string;
    totalQuantity: number;
    items: IShoppingListProduct[];
  }) => void;
  isReorderButtonDisabled: boolean;
}

export const ShoppingListSlider: FC<IShoppingListSliderProps> = ({ addListToCartHandler, isReorderButtonDisabled }) => {
  const history = useHistory();
  const contentStackPath = 'page_content.shopping_list[0].shopping_list_tab_content';
  const shoppingLists = useSelector(selectHomeShoppingLists);
  const isUserLocationDeleted = useSelector(selectIsUserLocationDeleted);

  useReorder();

  const isUnavailableForPurchaseItemsOnly = (items) =>
    items?.every(
      (item) =>
        item.isSpecialOrder || item.isDiscontinued || item.isOutOfStock || item.maximumQuantity === 0 || item.isInactive
    );

  return (
    <Swiper
      className={classNames('shopping-list-slider', {
        'shopping-list-slider--single-list': shoppingLists?.length === 1,
      })}
      modules={[Pagination, Navigation]}
      speed={500}
      navigation
      pagination
      loop
      breakpoints={{
        960: {
          allowTouchMove: false,
        },
        250: {
          allowTouchMove: true,
          pagination: {
            clickable: true,
          },
        },
      }}
    >
      {shoppingLists?.map(({ id, name, totalQuantity, totalItems, items }, index) => (
        <SwiperSlide key={id}>
          <div className="shopping-list-slider__content">
            <div className="shopping-list-slider__counter grid-container">
              <ContentstackText
                contentKey={`${contentStackPath}.slider_list_counter_text`}
                interpolateParams={{ currentListIndex: ++index, listsCount: shoppingLists?.length }}
              />
            </div>
            <div className="shopping-list-slider__list-info">
              <div className="grid-x grid-container">
                <div className="shopping-list-slider__list-name small-12 grid-y">
                  <span className="shopping-list-slider__list-block-label">
                    <ContentstackText contentKey={`${contentStackPath}.list_name_label`} />
                  </span>
                  <span className="shopping-list-slider__list-block-value">{name}</span>
                </div>
                <div className="shopping-list-slider__list-total-items small-6 grid-y">
                  <span className="shopping-list-slider__list-block-label">
                    <ContentstackText contentKey={`${contentStackPath}.total_number_of_items_label`} />
                  </span>
                  <span className="shopping-list-slider__list-block-value">{totalItems}</span>
                </div>
                <div className="shopping-list-slider__list-total-quantity small-6 grid-y">
                  <span className="shopping-list-slider__list-block-label">
                    <ContentstackText contentKey={`${contentStackPath}.total_quantity_label`} />
                  </span>
                  <span className="shopping-list-slider__list-block-value">{totalQuantity}</span>
                </div>
              </div>
            </div>
            <div className="grid-container shopping-list-slider__buttons">
              {size(items) >= 1 && (
                <PermissionAccess requiredPermissions={Permissions[AccessDefinitions.SHOPPING_CART_ACTIONS]}>
                  <CorButton
                    className="shopping-list-slider__button shopping-list-slider__add-all-to-cart small-12"
                    color="tertiary"
                    data-testid={`add-all-to-cart-${id}`}
                    onClick={() => addListToCartHandler({ items, totalItems, totalQuantity, id })}
                    disabled={
                      isUnavailableForPurchaseItemsOnly(items) || isUserLocationDeleted || isReorderButtonDisabled
                    }
                  >
                    <ContentstackText contentKey={`${contentStackPath}.add_all_to_cart_button_text`} />
                  </CorButton>
                </PermissionAccess>
              )}
              <CorButton
                onClick={() => history.push(`${Routes.ShoppingListDetailsPage}/${id}`)}
                className="shopping-list-slider__button shopping-list-slider__button-view-list small-12"
                color="tertiary"
              >
                <ContentstackText contentKey={`${contentStackPath}.view_button_text_mobile`} />
              </CorButton>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
